import './style.scss';

import Slider from 'rc-slider';
import React, { useRef, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { IoCameraOutline } from 'react-icons/io5';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../atoms/button/Button';
import Checkbox from '../../../atoms/checkbox/Checkbox';
import { setMultimediaThumbnail } from '../../../core/api/MultimediaAPI';
import { ElementsEnum } from '../../../core/ui/enums/ElementsEnum';
import { ScreenshotData } from '../../../helpers/screenshotElement';
import { usePropertyGridActive } from '../../../hooks/usePropertyGridActive';
import { C9ProjectDef } from '../../../model/definitions/C9ProjectDef';
import { TimeControlDef } from '../../../model/definitions/TimeControlDef';
import { VideoPanelDef } from '../../../model/definitions/VideoPanelDef';
import { ActiveDef, setPropertyGridActiveHash } from '../../../store/slices/active-slice';
import { updateVideoLayer } from '../../../store/slices/project-slice';
import { selectActiveVideoLayer } from '../../../store/slices/selectors';
import { RootState } from '../../../store/store';
import { PositionControls } from './panels/PositionControls';
import { TimeControlsPanel } from './panels/TimeControlsPanel';
import styles from './Properties.module.scss';
import SetVideoThumbnailModal from './SetVideoThumbnailModal';
import GridItem from './shared/GridItem';
import GridWrapper from './shared/GridWrapper';
import { getVolumeIcon } from './volumeIcon';

const VideoProperties: React.FC = () => {
  const dispatch = useDispatch();
  const [thumbnail, setThumbnail] = useState(false);
  const { activePoster, activeScene, activeElement } = useSelector<RootState, ActiveDef>(
    (state) => state.active,
  );
  const projectDef = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const { isOpened, lastFocused } = usePropertyGridActive(['name', 'description']);
  const videoLayer = useSelector<RootState, VideoPanelDef | undefined>((state) =>
    selectActiveVideoLayer(state),
  );
  const thumbnailMut = useMutation(setMultimediaThumbnail, {
    onSuccess: () => {
      setThumbnail(false);
    },
  });

  function onThumbnail() {
    setThumbnail(true);
  }

  /** drag to scroll **/
  const scrollableContainer = useRef<any>();
  const [startY, setStartY] = useState<any>();
  const [startX, setStartX] = useState<any>();
  const [scrollLeft, setScrollLeft] = useState<any>();
  const [scrollTop, setScrollTop] = useState<any>();
  const [isDown, setIsDown] = useState<any>();

  function mouseIsDown(e: any) {
    setIsDown(true);
    setStartY(e.pageY - scrollableContainer.current.offsetTop);
    setStartX(e.pageX - scrollableContainer.current.offsetLeft);
    setScrollLeft(scrollableContainer.current.scrollLeft);
    setScrollTop(scrollableContainer.current.scrollTop);
  }
  function mouseUp(e: any) {
    // e.stopPropagation();
    // e.preventDefault();
    setIsDown(false);
  }
  function mouseLeave(e: any) {
    // e.stopPropagation();
    // e.preventDefault();
    setIsDown(false);
  }
  function mouseMove(e: any) {
    if (isDown) {
      e.preventDefault();
      //Move vertcally
      const y = e.pageY - scrollableContainer.current.offsetTop;
      const walkY = y - startY;
      scrollableContainer.current.scrollTop = scrollTop - walkY;

      //Move Horizontally
      const x = e.pageX - scrollableContainer.current.offsetLeft;
      const walkX = x - startX;
      scrollableContainer.current.scrollLeft = scrollLeft - walkX;
    }
  }
  /** drag to scroll **/
  function onVideoLayerChange(propertyPath: Leaves<VideoPanelDef>, e: string | boolean | number) {
    onFocus(propertyPath);
    if (propertyPath === 'loopVideo') {
      const scene = projectDef.sceneDefs.find((scene) => scene.id === activeScene);
      dispatch(
        updateVideoLayer({
          newValue:
            propertyPath === 'loopVideo' && !!e
              ? [new TimeControlDef(0, scene?.durationInMS)]
              : [new TimeControlDef(0, videoLayer?.videoPanelDefTemplate.durationInMS)],
          activeScene: activeScene,
          elementId: activeElement,
          //@ts-ignore
          propertyPath: 'timeControls',
          parentId: activePoster,
        }),
      );
    }
    dispatch(
      updateVideoLayer({
        newValue: e,
        activeScene: activeScene,
        elementId: activeElement,
        propertyPath: propertyPath,
        parentId: activePoster,
      }),
    );
  }
  function onFocus(path: Leaves<VideoPanelDef>) {
    dispatch(setPropertyGridActiveHash({ activeElement, focusedEl: path }));
  }

  function onConfirm(data: ScreenshotData) {
    thumbnailMut.mutate({
      multimediaType: 'VIDEO',
      versionId: videoLayer!.videoPanelDefTemplate.versionId,
      thumbnail: data,
    });
  }
  const [accordionTxtProp, setAccordionTxtProp] = useState<boolean>(isOpened);

  const { positionControl, timeControls } = videoLayer ?? new VideoPanelDef();
  return (
    <>
      <div
        className={styles.wrapper}
        ref={scrollableContainer}
        onMouseDown={(e) => mouseIsDown(e)}
        onMouseUp={(e) => mouseUp(e)}
        onMouseLeave={(e) => mouseLeave(e)}
        onMouseMove={(e) => mouseMove(e)}
      >
        <div className="flex items-center justify-end gap-x-4 right-[10px] top-[3px]">
          <Button
            buttonType="border"
            label="Set thumbnail"
            icon={<IoCameraOutline />}
            onClick={onThumbnail}
            className="property-grey-buttons"
          />
        </div>
        {!activePoster && (
          <>
            <div
              className={`mb-2 subheader layer-header ${
                accordionTxtProp ? 'layer-header-active' : ''
              }`}
              onClick={() => setAccordionTxtProp(!accordionTxtProp)}
            >
              {accordionTxtProp ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
              Video properties
            </div>
            {accordionTxtProp && (
              <GridWrapper>
                <GridItem
                  label={'Name'}
                  item={
                    <DebounceInput
                      debounceTimeout={400}
                      onFocus={() => onFocus('name')}
                      autoFocus={lastFocused === 'name'}
                      value={videoLayer?.name}
                      onChange={(e) => onVideoLayerChange('name', e.target.value)}
                    />
                  }
                />
                <GridItem
                  label={'Description'}
                  item={
                    <DebounceInput
                      debounceTimeout={400}
                      onFocus={() => onFocus('description')}
                      autoFocus={lastFocused === 'description'}
                      value={videoLayer?.description}
                      onChange={(e) => onVideoLayerChange('description', e.target.value)}
                    />
                  }
                />
                <GridItem
                  label={'Enable audio'}
                  noBorderBg
                  item={
                    <Checkbox
                      onFocus={() => onFocus('enableAudio')}
                      autoFocus={lastFocused === 'enableAudio'}
                      checked={videoLayer?.enableAudio}
                      onChange={(e) => onVideoLayerChange('enableAudio', e.target.checked)}
                    />
                  }
                />
                <GridItem
                  noBorderBg
                  label={`Volume`}
                  item={
                    <>
                      {getVolumeIcon(Number(videoLayer?.audioVolume))}
                      <Slider
                        style={{ width: '100%' }}
                        min={0}
                        max={100}
                        step={1}
                        value={(videoLayer?.audioVolume ?? 0) * 100}
                        onChange={(e) => {
                          e >= 0 &&
                            e <= 100 &&
                            onVideoLayerChange(`audioVolume`, (Number(e) / 100).toFixed(2));
                        }}
                        disabled={!videoLayer?.enableAudio}
                      />
                      <div style={{ marginLeft: '0.5rem' }}>
                        {Math.round((videoLayer?.audioVolume ?? 0) * 100)}
                      </div>
                    </>
                  }
                />
                <GridItem
                  label={'Loop video'}
                  noBorderBg
                  item={
                    <Checkbox
                      onFocus={() => onFocus('loopVideo')}
                      autoFocus={lastFocused === 'loopVideo'}
                      checked={videoLayer?.loopVideo}
                      onChange={(e) => onVideoLayerChange('loopVideo', e.target.checked)}
                      disabled={!videoLayer?.enableAudio}
                    />
                  }
                />
                <GridItem
                  noBorderBg
                  label={`Opacity`}
                  item={
                    <>
                      <Slider
                        style={{ width: '100%' }}
                        min={0}
                        max={1}
                        step={0.01}
                        value={videoLayer?.opacity}
                        onChange={(e) => {
                          e >= 0 &&
                            e <= 1 &&
                            typeof e === 'number' &&
                            onVideoLayerChange(`opacity`, Number(e));
                        }}
                        disabled={!videoLayer?.enableAudio}
                      />
                      <div style={{ marginLeft: '0.5rem', fontVariantNumeric: 'tabular-nums' }}>
                        {videoLayer?.opacity.toFixed(2)}
                      </div>
                    </>
                  }
                />
              </GridWrapper>
            )}
          </>
        )}
        {!activePoster && (
          <>
            <PositionControls position={positionControl} layer={'videoPanels'} />
            <TimeControlsPanel timeControls={timeControls} layer={ElementsEnum.VIDEO} />
          </>
        )}
        {videoLayer && (
          <SetVideoThumbnailModal
            onConfirm={onConfirm}
            opened={thumbnail}
            onClose={() => setThumbnail(false)}
            loading={thumbnailMut.isLoading}
            videoLayerId={videoLayer.id}
            videoLayerVersionId={videoLayer.versionId}
          />
        )}
      </div>
    </>
  );
};

export default VideoProperties;
